import moment from 'moment';//时间格式化插件
/**
 * 日期格式化(部分可用,不推荐)
 */
export const dateYMDFormat = (dateStr, pattern = 'YYYY-MM-DD') => {
    if (dateStr !== 'Invalid Date') {
        return moment(dateStr).format(pattern);
    }
}
//年月日时分秒
export const dateYMDhmsFormat = (dateStr, pattern = 'YYYY-MM-DD HH:mm:ss') => {
    if (dateStr !== 'Invalid Date') {
        return moment(dateStr).format(pattern);
    }
}
//年月日时分
export const dateYMDhmFormat = (dateStr, pattern = 'YYYY-MM-DD HH:mm') => {
    if (dateStr !== 'Invalid Date') {
        return moment(dateStr).format(pattern);
    }
}
//年月日时分秒
export const dateHMFormat = (dateStr) => {
    console.info(dateStr)
    if (dateStr !== 'Invalid Date') {
        let date = new Date();
        let startTimeArr = dateStr.split(":");
        date.setHours(parseInt(startTimeArr[0]));
        date.setMinutes(parseInt(startTimeArr[1]));
        date.setSeconds(0);
        return date
    }
}
/**
 * 日期格式化（时间戳及字符串）
 */
export const dateFormat = (time, format) => {
    var t = new Date(time)
    var tf = function (i) {
        return (i < 10 ? '0' : '') + i
    }
    return format ? format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
        switch (a) {
            case 'yyyy':
                return tf(t.getFullYear())
            case 'MM':
                return tf(t.getMonth() + 1)
            case 'mm':
                return tf(t.getMinutes())
            case 'dd':
                return tf(t.getDate())
            case 'HH':
                return tf(t.getHours())
            case 'ss':
                return tf(t.getSeconds())
        }
    }) : '';
}
