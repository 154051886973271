import axios from 'axios'
import qs from 'qs'
import {ElMessage} from 'element-plus'
import localStore from "../utils/storage"

const sessionTimeoutHandler = () => {
    localStore.remove('warehouse_userInfo')
    localStore.remove('warehouse_username')
    localStore.remove('warehouse_token')

    let timeoutId = setTimeout(() => {
        window.vue.$children[0].isToken = false;
        clearTimeout(timeoutId);
    }, 1000);
}
// 添加请求拦截器
axios.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    config.headers['token'] = localStore.get('warehouse_userInfo')?localStore.get('warehouse_userInfo').token:'';
    return config;
}, err => {
    // 对请求错误做些什么
    console.error(err);
    ElMessage({message: '请求超时!' + err});
})
// 添加响应拦截器
axios.interceptors.response.use(data => {

    // 对响应数据做点什么
    if (data.data.code && data.data.code == 500217) {
        ElMessage({message: data.data.msg});
        this.$router.push('/Login');
        sessionTimeoutHandler();

        return;
    }
    if (data.status && data.status == 200 && data.data.status == 500) {
        ElMessage({message: data.data.msg});
        return;
    }
    // if (data.data.msg) {
    //     ElMessage({message: data.data.msg});
    // }
    return data.data;
}, err => {
    if (err.response.data && err.response.data.code == 500210) {
        ElMessage({message: err.response.data.msg});
        sessionTimeoutHandler();
        return;
    }
    // 对响应错误做点什么
    if (err.response.status == 504 || err.response.status == 404) {
        ElMessage({message: '页面丢失404,504'});
    } else if (err.response.status == 403) {
        ElMessage({message: '权限不足,请联系管理员!'});
    } else if (err.response.status == 401) {
        ElMessage({message: err.response.data.msg});
    } else {
        if (err.response.data.msg) {
            ElMessage({message: err.response.data.msg});
        } else {
            ElMessage({message: '未知错误!'});
        }
    }
})

// 正式服务器
export const base = process.env["VUE_APP_BASE_URL"];
export const channel = process.env["VUE_APP_BASE_CHANNEL"];


export const putRequest = (url, params) => {
    return axios({
        method: 'put',
        url: `${base}${url}`,
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const deleteRequest = (url) => {
    return axios({
        method: 'delete',
        url: `${base}${url}`
    });
}
export const getRequest = (url, params) => {
    var pp = "";
    var first = true;
    for (var val in params) {
        if (first) {
            pp = pp + "?" + val + "=" + params[val];
            first = false
        } else {
            pp = pp + "&" + val + "=" + params[val];
        }
    }
    var url1 = url + pp;
    return axios({
        method: 'get',
        url: `${base}${url1}`
    });
}
export const postRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const getFormRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        transformRequest: [function (data) {      //在请求之前对data传参进行格式转换
            data = qs.stringify(data)
            return data
        }],
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    });
}
export const postFormRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        transformRequest: [function (data) {      //在请求之前对data传参进行格式转换
            data = qs.stringify(data)
            return data
        }],
        data: params,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    });
}
export const postImgRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        responseType: 'application/json',   //这里是声明期望返回的数据类型，为blob
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
export const getRequestApi = (url, params) => {
    var pp = "";
    var first = true;
    for (var val in params) {
        if (first) {
            pp = pp + "?" + val + "=" + params[val];
            first = false
        } else {
            pp = pp + "&" + val + "=" + params[val];
        }
    }
    var url1 = url + pp;
    return axios({
        method: 'get',
        url: `${url1}`,
        data: params,
        headers: {
            'Content-Type': 'application/json',
        }
    });
}
//get导出
export const getDownload = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        responseType: "blob",
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
//post导出
export const postDownload = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        responseType: "blob",
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    });
}
//导入excel模板
export const postImportExcel = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

//websocket
export const heartbeat = function (callback) {
    let socket = null;
    let isConnected = false;
    //心跳消息是否返回
    let isHeartbeatMessageReturn = false;
    let heartbeatTimeoutObj = null;
    //没有回应的心跳的次数
    let heartbeatMessageMissCount = 0;
    //重新连接次数
    let reconnectCount = 3;
    //开启心跳
    function startCheck() {
        console.log(isHeartbeatMessageReturn)
        if (!isHeartbeatMessageReturn) {
            heartbeatMessageMissCount++;
        } else {
            if(heartbeatMessageMissCount > 0){
                heartbeatMessageMissCount--;
            }
        }
        if (socket) {
            heartbeatTimeoutObj && clearTimeout(heartbeatTimeoutObj);
            console.log(heartbeatMessageMissCount)
            //如果连续三次没有得到回应，就认为服务器已经挂了
            if (heartbeatMessageMissCount == 3 || !isConnected) {
                heartbeatMessageMissCount = 0;
                // socket.close();
                return;
            }

            heartbeatTimeoutObj = setTimeout(function () {
                send(localStore.get("warehouse_token"));
                send('1');
                console.log('心跳检测')
                startCheck();
            }, 5000);
        }
    }

    //初始化websocket
    function init() {
        if (typeof (WebSocket) === "undefined") {
            alert("您的浏览器不支持socket")
        } else {
            // 实例化socket
            if (socket) {
                socket.close();
                socket = null;
            }

            //消息推送链接
            socket = new WebSocket(process.env["VUE_APP_BASE_Socket"])

            // 监听socket连接
            socket.onopen = open
            // 监听socket错误信息
            socket.onerror = error
            // 监听socket消息
            socket.onmessage = getMessage
            socket.onclose = onclose;
        }
    }

    //重新连接
    function reconnect() {
        console.log(isConnected+ '  '+ reconnectCount);
        console.log('重新连接')
        //没连接上重新连接三次
        if (isConnected || reconnectCount == 0) {
            reconnectCount = 3;
            socket.close();
            return;
        }
        init();
        reconnectCount--;
    }
    function open() {
        isConnected = true;
        startCheck();
        console.log("socket连接成功")
        send(localStore.get("warehouse_token"))
    }
    function error() {
        isConnected = false;
        if(reconnectCount > 0){
            reconnect();
        }
        console.log("连接错误")

    }
    function getMessage(msg) {
        if (msg.data != null) {
            if(msg.data == "welcome"){
                isHeartbeatMessageReturn = true;
                return;
            }
            if (msg != 'welcome' && callback) {
                console.log(msg)
                callback(msg.data);
            }
            startCheck();
        }
    }
    function send(params) {
        if(socket){
            socket.send(params)
        }
    }
    function onclose(){
        isConnected = false;
    }
    function close() {
        socket.close();
        socket = null;
        heartbeatTimeoutObj = null;
        console.log("socket已经关闭")
    }
    this.close = close;
    this.send = send;
    init();
    return this;
};
