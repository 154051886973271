<template>
  <div class="Main">
    <div class="header">产品质量证明书（新）</div>
    <!--    产品基本信息-->
    <div class="itemInfos">产品基本信息</div>
    <div class="itemInfo">产品名称：<span class="item">{{dataList.cInvName}}</span></div>
    <div class="itemInfo">生产日期：<span class="item">{{ dateYMDFormat(dataList.stockTime) }}</span></div>
    <div class="itemInfo">批号：<span class="item">{{ dataList.batchCode }}</span></div>
    <div class="itemInfo">捆号：<span class="item">{{ dataList.bundleCode }}</span></div>
    <div class="itemInfo">牌号：<span class="item">{{ dataList.steelGradeName }}</span></div>
    <div class="itemInfo">规格（mm）：<span class="item">{{ dataList.cInvStd }}</span></div>
    <div class="itemInfo">定尺：<span class="item">{{ dataList.fixedLength = '0' ? '定尺' : '非定尺' }}</span></div>
    <!--    物理性能-->
    <div class="itemInfos">物理性能</div>
    <div class="itemInfo" v-for="(item,index) in tableDate" :key="index">
      {{ item.projectCategory }}：<span class="item">{{ item.itemVal2 ? item.itemVal2 : '0' }}</span></div>
    <!--    公司-->
    <div class="header">
      <el-image class="image" :src="require('../assets/image/logo3.png')"></el-image>
      <span>四川雅安安山钢铁有限公司</span>
    </div>
    <div class="footer">
      <div>地址：<span>四川·雅安</span></div>
      <div>邮编：<span>625599</span></div>
      <div>网址：<a style="color: #0000FF" href=""></a></div>
      <div>联系我们：
        <el-table
            :data="tableList"
            :header-row-style="{color:'#000000',height:'30px',fontWeight:'Medium'}"
            :header-cell-style="{color:'#000000',height:'30px',fontWeight:'Medium',textAlign:'center'}">
          <el-table-column
              prop="name"
              label="产品名称">
          </el-table-column>
          <el-table-column
              prop="tel"
              label="联系电话"
              align="center"
              width="120px">
            <template #default>
              <a href="tel:0835-5995959">0835-5995959</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "CertificationOfProduct",
  data() {
    return {
      dataList: [],
      tableDate: [],
      tableList: [
        {name: '热轧带肋高强度抗震钢筋', tel: '0835-5995959'},
        {name: '热轧光圆钢筋', tel: '0835-5995959'},
      ]
    }
  },
  mounted() {
    document.title = "安山钢铁有限公司-产品质量证明书"
    let url = window.location.href;
    let arr = url.split("?");
    let param = null;
    if (arr.length > 1) {
      let paramPair = arr[1].split("=");
      if (paramPair.length > 1) {
        param = paramPair[1];
      }
    }
    this.getTableList(param);
  },
  methods: {
    getTableList(param) {
      param = param ? param : '';
      this.getRequest("/inspect/info?p=" + param).then(res => {
        if (res.success) {
          this.dataList = res.data;
          this.tableDate = res.data.physicalProperty;
        }
      })
    }
  }
}
</script>

<style scoped>
.Main {
  width: 100%;
  background-image: url("../assets/image/bj.jpg");
  height: 100%;
  overflow: scroll;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin: 15px 0 0 0;
}

.itemInfos {
  color: black;
  padding: 30px 0 10px 15px;
  font-size: 14px;
  border-bottom: 1px dashed #185598;
}

.itemInfo {
  padding: 10px 15px 10px 15px;
  font-size: 14px;
  color: #808080;
  border-bottom: 1px dashed #0066CC;
}

.item {
  float: right;
  color: #808080;
}

.image {
  width: 40px;
}

.footer {
  padding: 10px 10px 10px 15px;
  line-height: 20px;
  font-size: 14px;
}

.el-table::before {
  /*border: 1px dashed #0066CC;*/
  border-bottom: 1px dashed #0066CC;
}

.el-table th, /deep/ .el-table tr {
  background-image: url("../assets/image/bj.jpg");
  height: 40px;
}

/deep/ .el-table td, .el-table th {
  border-bottom: 1px dashed #0066CC;
  border-left: 1px dashed #0066CC;
}

/deep/ .el-table_1_column_2 {
  border-right: 1px dashed #0066CC;
}

/deep/ .el-table th.is-leaf {
  /*border: 1px dashed #0066CC;*/
  border-top: 1px dashed #0066CC;
  border-left: 1px dashed #0066CC;
  border-bottom: 1px dashed #0066CC;
  background-image: url("../assets/image/bj.jpg");
}
a, a:focus, a:hover{
  text-decoration:none;
  color: #0000FF;
}
</style>
